<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('app_messages.title')"
        :paths="[{title:$t('settings.title'),link:'settings'}]"

        :btn-title="$t('app_messages.new_btn')"

        :btn="true"
        @btnAction="showModal = true;modalData=null"
    />

    <div>
      <filter-header
          :content="filterContent"
          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderData"

      />
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      ">
      <div class="d-flex flex-wrap flex-1">
        <b-col lg="12" md="12" class="px-0">

          <div class="w-table d-flex flex-column">
            <w-tables
                :noDataTitle="$t('table.no_data_title')"
                :noDataSubtitle="$t('table.no_data_subtitle')"
                v-if="getData"
                :per-page="filterData.per_page"
                :items="getData"
                :fields="tableFields"
                :custimized-items="[
                { name: 'location' },
                { name: 'status' },
                { name: 'action' },
              ]"
            >


              <template slot="location" slot-scope="{ data }">
                <div v-if="data.item.location && data.item.location.length>0">
                  {{ data.item.location.toString().replace('[','').replace(']','').replace('"','') }}
                </div>
                <div v-else>
                  {{ $t('app_messages.all') }}
                </div>
              </template>


              <template slot="status" slot-scope="{ data }">
                <wameed-switch
                    :checked="data.item.status ===1"
                    @onChange="()=>onChange(data.item)"/>
              </template>

              <template slot="action" slot-scope="{ data }"
                        v-if="data.item.type==null">
                <b-dropdown
                    variant="background"
                    class="table-option"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-8"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon/>
                  </template>

                  <b-dropdown-item
                      class="permission-edit-item"
                      v-permission="[$route.meta.pagePermissions.edit]"
                      @click="()=> openUpdateModal(data.item)"
                  >
                    <span class="text-regular-14 text-font-secondary">

                      <edit-icon class="mx-2"/>
                      {{ $t('btn.edit') }}
                    </span>
                  </b-dropdown-item>

                  <b-dropdown-item
                      class="permission-delete-item"
                      v-permission="[$route.meta.pagePermissions.delete]"
                      @click="deleteModal = true;  deleteModalData= data.item">
                    <span class="text-regular-14  text-danger">
                      <trash-icon class="mx-2 "/>
                      {{ $t('btn.delete') }}
                    </span>
                  </b-dropdown-item>

                </b-dropdown>
              </template>
            </w-tables>
          </div>

        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-if="getTotal"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotal.totalItems"
              :per_page="filterData.per_page"
              :menu-title="$t('common.show_results')"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>

      </div>

    </section>

    <app-message-modal
        :visible="showModal"
        @close="showModal = false"
        :modal-data="modalData"
        @onComplete="()=>loadData()"
    />


    <warning-modal
        variant="danger"
        iconName="trash-icon"
        :visible="deleteModal"
        @close="deleteModal = false"
        @submitAction="deleteAction"
        :title="$t('app_messages.modal.delete')"
        :subTitle="$t('app_messages.modal.delete_desc')"
        :btnTitle="$t('btn.delete')"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';
import WarningModal from "@/components/WarningModal";

import {WameedPagination, WameedSwitch, WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'
import appMessageModal from "@/views/pages/settings/app_messages/components/appMessageModal";

export default {
  components: {
    appMessageModal,
    WarningModal,
    WameedPagination,
    PageHeader,
    FilterHeader,
    WameedSwitch,
    WTables,
  },
  data() {
    return {
      showModal: false,
      deleteModal: false,
      deleteModalData: null,
      modalData: null,
      filterData: {
        status: '',
        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: '',
      },
      filterContent: [
        {
          title: this.$i18n.t('table.availability'),
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          translate: true,
          type: 'text',
          data: [
            {
              id: 'active',
              title: this.$i18n.t('status.available'),
            },
            {
              id: 'not_active',
              title: this.$i18n.t('status.not_available'),
            },
          ],
        },
      ],
      tableFields: [
        {
          key: 'index',
          label: '#',
          sortable: false,

        },
        {
          key: 'name',
          label: this.$i18n.t('app_messages.form.name'),
          sortable: false,
          tdClass: 'w-1/7'
        },

        {
          key: 'message',
          label: this.$i18n.t('app_messages.form.message_ar'),
          sortable: false,
          tdClass: 'w-2/7'
        },
        {
          key: 'location',
          label: this.$i18n.t('app_messages.form.location'),
          sortable: false,
          tdClass: 'w-2/7'
        },
        {
          key: 'status',
          label: this.$i18n.t('table.availability'),
          sortable: false,
          tdClass: 'w-1/7'
        },
        {
          key: 'action',
          label: this.$i18n.t('table.options'),
          tdClass: 'w-1/7'
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getData: 'admin/settings/app_messages/getData',
      getTotal: 'admin/settings/app_messages/getTotal',
    }),
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      _getData: 'admin/settings/app_messages/get',
      _deleteData: 'admin/settings/app_messages/delete',
    }),


    openUpdateModal(item) {

      this.$store.dispatch('admin/settings/app_messages/detail', {id: item.id}).then((items) => {
        this.showModal = true;

        this.modalData = {
          id: item.id,
          name: item.name,
        };
        if (items.locations.length === 0) {
          this.modalData['location'] = [{id: -1, name: 'all'}]
        } else if (items.locations && items.locations.length > 0) {
          if (items.locations[0].id == null) {
            this.modalData['location'] = [{id: -1, name: 'all'}]
          } else {
            this.modalData['location']=[];
            items.locations.forEach((item) => {

              if (item['id'] == 0) {
                this.modalData['location'].push({id: item['id'], name: this.$t('nav.dashboard')});
              } else if (item['id'] == -1) {
                this.modalData['location'].push({id: item['id'], name: 'all'});
              } else {
                this.modalData['location'].push({id: item['id'], name: item['name']});
              }
            })
          }
        }


        items.messages.forEach((item) => {
          this.modalData['message_' + item['locale']] = item['message']
        })
      })

    },

    onChange(item) {
      let status = 1;
      if (item.status === 1) {
        status = 0;
      }

      this.$store.dispatch('admin/settings/app_messages/toggle', {id: item.id, status: status})
    },

    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page:1,
      };
      this.loadData();
    },

    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },

    loadData() {

      this._getData(this.filterData);
    },

    deleteAction() {
      this.deleteModal = false
      this._deleteData({id: this.deleteModalData.id});
    },


    applyFilter() {
      this.filterContent.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    resetFilter() {
      this.filterContent.forEach((item, i) => {
        this.filterContent[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    orderData(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },
};
</script>

<template>
  <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      class="repeater__form"
      style="overflow: visible"
  >
    <b-modal
        v-model="show"
        content-class="package-modal rounded-8"
        dialog-class="wameed-modal-md"
        no-fade
        :key="key"
    >
      <template slot="modal-header-close">
        <close-icon/>
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            {{ modalData != null ? $t('app_messages.update_btn') : $t('app_messages.new_btn') }}
          </h3>
        </div>
      </template>


      <b-row>

        <b-col cols="12" md="6">
          <TextInput
              v-model="form.name"
              rules="required"
              field-classes="w-100"
              :label="$t('app_messages.form.name')"
              :placeholder="$t('form.text.placeholder')"
          />
        </b-col>

        <b-col cols="12" md="6">
          <wameed-dropdown
              v-model="form.location"
              :label="$t('app_messages.form.location')"
              :placeholder="$t('form.text.placeholder')"
              variant="disable"
              :items="getServices"
              title="name"
              :multiple="true"
              :clearable="true"
              class="wameed_dropdown"
              rules="required"
              @close="hideDropDown"
              @setSelectAll="whenSelectAll"
              :selectAll="selectAll"
              :selected-all-text="$t('form.all.selected')"
              :select-all-check-box="$t('form.all.checkBox')"
              :no_options="$t('common.no_options')"
          />
        </b-col>

        <b-col md="12" v-for="locale in locales" :key="locale">
          <text-input
              v-model="form['message_'+locale]"
              :label="$t('app_messages.form.message_'+locale)"
              :placeholder="$t('form.text.placeholder')"
              name="package-ad"
              rules="required"
          />
        </b-col>


      </b-row>

      <template slot="modal-footer">
        <wameed-btn
            classes="  text-med-14 text-white mx-0 rounded-8"
            :title="modalData!=null ?$t('btn.update'):$t('btn.add')"
            :disabled="invalid"
            type="submit"
            variant="main"
            @onClick="submitOrder()"
        />
        <wameed-btn
            classes="  text-med-14 text-font-secondary mx-0 rounded-8 "
            :title="$t('btn.cancel')"
            type="button"
            variant="gray"
            @onClick="closeModal()"
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {WameedBtn, WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm';
import {mapActions, mapGetters} from 'vuex';
import {availableLocales} from '@/enums/locales.enum'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    modalData: {
      type: Object,
      default: null,
    },

  },

  data() {
    return {
      key: 0,
      locales: availableLocales,
      selectAll: false,
      form: {
        location: '',
        name: '',
      },
      showSuccessModal: false,
    };
  },
  computed: {
    ...mapGetters({
      getServices: 'admin/settings/app_messages/getServices',
    }),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.form={};

          this.selectAll = false;
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    modalData(newVal) {
      if (newVal != null) {
        this.form = newVal;
        if (this.form.location && this.form.location.length > 0 && this.form.location[0].id === -1) {
          this.selectAll = true;
        }
      } else {
        this.form={};
        this.selectAll = false;
      }
    },
  },
  async created() {
    if (this.modalData != null) {
      this.form = this.modalData
    }

    await this.$store.dispatch('admin/settings/app_messages/getServices');
  },
  methods: {
    ...mapActions({
      create: "admin/settings/app_messages/create",
      update: "admin/settings/app_messages/update",
    }),

    hideDropDown(){
      this.key++;
    },
    whenSelectAll() {
      this.selectAll = !this.selectAll;
    },
    submitOrder() {

      if (this.selectAll) {
        this.form.location = [{id: -1, name: 'all'}]
      }
      if (this.modalData == null) {
        this.create(this.form).then(() => {
          this.$emit('onComplete', true);
          this.closeModal();
          this.$store.commit('loadingFinish', null, {root: true});
        });
      } else {
        this.update({
          ...this.form,
          id: this.modalData.id,

        }).then(() => {
          this.$emit('onComplete', true);
          this.closeModal();
          this.$store.commit('loadingFinish', null, {root: true});
        });
      }
    },


    closeModal() {
      this.form = {};
      this.show = false;
      this.selectAll = false;
    },
  },
};
</script>
